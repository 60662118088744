import type {
  AppExposedApis,
  EditorReadyFn,
  EditorSDK,
  PopupData,
} from '@wix/platform-editor-sdk';
import type { GetAppManifestFn, TFunction } from '@wix/yoshi-flow-editor';
import { getSettingsTabs } from './utils/getSettingsTabs';
import {
  APP_DEF_IDS,
  MENUS_PAGES_GROUP,
  TABLE_RESERVATIONS_GROUP,
  ORDERS_PAGES_GROUP,
  DASHBOARD_URLS,
  MENUS_APP_IMAGE_GALLERY_LIGHTBOX,
} from '@wix/restaurants-consts';
import { TOKEN } from './utils/consts';
import { getOloApi } from './utils/getOloAPI';
import { isAppInstalled } from './utils/isAppInstalled';
import type {} from '@wix/app-manifest-builder/dist/types';
import {
  buildConfigureState,
  configureStates,
  getOloPageConfigureState,
} from './utils/configureState.utils';

export const editorReady: EditorReadyFn = async () => {};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  contextParams,
  flowAPI,
) => {
  const { translations, environment, httpClient, experiments } = flowAPI;
  const t = translations.t as TFunction;
  const settingsTabs = getSettingsTabs(t);
  const isEditorX = environment.isEditorX;
  const isMultiPages = experiments.enabled('specs.restaurants.oloMultiPages');
  const isRestaurantsCategoryNewNameEnabled = experiments.enabled(
    'se_restaurantsCategoryNewName',
  );

  const oloPageIds: string[] = [];

  if (isMultiPages) {
    const isOLOAppInstalled =
      await editorSDK.application.isApplicationInstalled('', {
        appDefinitionId: APP_DEF_IDS.orders,
      });
    if (isOLOAppInstalled) {
      const oloApi = await getOloApi(editorSDK);
      oloPageIds.push(...((await oloApi?.getOloPageIds?.(httpClient)) ?? []));
    }
  }

  return appManifestBuilder
    .configurePages((pagesBuilder) => {
      pagesBuilder.set({ icon: 'restaurantsPageType' });
      pagesBuilder.addSettingsTab(settingsTabs.permissions);
      pagesBuilder.addSettingsTab(settingsTabs.layout);
      configureStates.forEach((configureState) =>
        buildConfigureState({
          configureState,
          isEditorX,
          pagesBuilder,
          settingsTabs,
          t,
        }),
      );
      if (isMultiPages) {
        oloPageIds.forEach((oloPageId) => {
          const configureState = getOloPageConfigureState(oloPageId);

          buildConfigureState({
            configureState,
            isEditorX,
            pagesBuilder,
            settingsTabs,
            t,
          });
        });
      }
    })
    .configurePagesTab((pagesTabBuilder) => {
      // application settings
      pagesTabBuilder
        .set({
          displayName: isRestaurantsCategoryNewNameEnabled
            ? t('editor-script.panel.section-name.foodbeverage')
            : t('editor-script.panel.section-name'),
        })
        .setGrouping([
          {
            id: 'wix-menus',
            title: t('editor-script.groups.menus'),
            pages: MENUS_PAGES_GROUP,
          },
          {
            id: 'wix-online-orders',
            title: t('editor-script.groups.orders'),
            pages: [...oloPageIds, ...ORDERS_PAGES_GROUP],
          },
          {
            id: 'wix-table-reservations',
            title: t('editor-script.groups.reservations'),
            pages: TABLE_RESERVATIONS_GROUP,
          },
        ]);
    })
    .configureManagementActions((managementActionsBuilder) => {
      managementActionsBuilder.customActions().addAction(
        {
          title: 'Manage Menus',
          icon: 'appManager_settingsAction',
          type: 'dashboard',
          onClick: (event) => {
            editorSDK.editor.openDashboardPanel(TOKEN, {
              url: DASHBOARD_URLS.menusUrl,
              closeOtherPanels: false,
            });
          },
        },
        {
          title: 'Manage Table Reservations',
          icon: 'appManager_settingsAction',
          type: 'dashboard',
          onClick: (event) => {
            editorSDK.editor.openDashboardPanel(TOKEN, {
              url: DASHBOARD_URLS.tableReservationsUrl,
              closeOtherPanels: false,
            });
          },
        },
        {
          title: 'Manage Table Reservations Settings',
          icon: 'appManager_settingsAction',
          type: 'dashboard',
          onClick: (event) => {
            editorSDK.editor.openDashboardPanel(TOKEN, {
              url: DASHBOARD_URLS.tableReservationsSettingsUrl,
              closeOtherPanels: false,
            });
          },
        },
      );
    })
    .build();
};

export const exports = (editorSDK: EditorSDK): AppExposedApis => ({
  editor: {
    removeAppCompleted: async ({ appDefinitionId }) => {
      const isMenusAppInstalled = await isAppInstalled(
        editorSDK,
        APP_DEF_IDS.menus,
      );
      const isTableReservationsAppInstalled = await isAppInstalled(
        editorSDK,
        APP_DEF_IDS.reservations,
      );
      const isOLOAppInstalled = await isAppInstalled(
        editorSDK,
        APP_DEF_IDS.orders,
      );

      const isOnlyOLOLeft =
        appDefinitionId === APP_DEF_IDS.orders &&
        !isMenusAppInstalled &&
        !isTableReservationsAppInstalled;

      const isOnlyMenusLeft =
        appDefinitionId === APP_DEF_IDS.menus &&
        !isOLOAppInstalled &&
        !isTableReservationsAppInstalled;

      const isOnlyTableReservationsLeft =
        appDefinitionId === APP_DEF_IDS.reservations &&
        !isOLOAppInstalled &&
        !isMenusAppInstalled;

      if (isOnlyOLOLeft || isOnlyMenusLeft || isOnlyTableReservationsLeft) {
        editorSDK.application.uninstall('', { openConfirmation: false });
      }
    },
  },
  public: {
    isImageLightboxInstalled: async () => {
      const installedLightboxes =
        await editorSDK.pages.popupPages.getApplicationPopups(TOKEN);
      return installedLightboxes.find(
        (lightbox: PopupData) =>
          lightbox.title === MENUS_APP_IMAGE_GALLERY_LIGHTBOX,
      );
    },
  },
});
