import type {
  PageActionsTypeStrings,
  SimplePageAction,
} from '@wix/platform-editor-sdk';
import type { TFunction } from '@wix/yoshi-flow-editor';

export type PAGE_ACTION_TYPE = SimplePageAction & { id?: string };

export const getRenameAction = (
  t: TFunction,
  isEditorX?: boolean,
): PAGE_ACTION_TYPE => {
  return isEditorX
    ? {
        type: 'Pages_Actions_Page_Rename' as PageActionsTypeStrings,
        title: t('editor-script.actions.rename', 'Rename'),
        id: 'pages/rename',
      }
    : {
        type: 'page_rename',
      };
};

export const getDeleteAction = (
  t: TFunction,
  isEditorX?: boolean,
): PAGE_ACTION_TYPE => {
  return isEditorX
    ? {
        type: 'page_delete' as PageActionsTypeStrings,
        title: t('editor-script.actions.delete', 'Delete'),
        id: 'pages/delete',
      }
    : {
        type: 'page_delete',
      };
};

export const getSetAsHomePageAction = (
  t: TFunction,
  isEditorX?: boolean,
): PAGE_ACTION_TYPE => {
  return isEditorX
    ? {
        type: 'page_set_as_homepage',
        title: t('editor-script.actions.set-as-homepage', 'Set as homepage'),
        id: 'pages/setAsHomePage',
      }
    : {
        type: 'page_set_as_homepage',
      };
};

export const getDuplicateAction = (
  t: TFunction,
  isEditorX?: boolean,
): PAGE_ACTION_TYPE => {
  return isEditorX
    ? {
        type: 'page_duplicate',
        title: t('editor-script.actions.duplicate', 'Duplicate'),
        id: 'pages/duplicate',
      }
    : {
        type: 'page_duplicate',
      };
};
