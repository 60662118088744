import {
  MENUS_PAGE_ID,
  ORDERS_PAGES,
  RESERVATIONS_PAGES,
} from '@wix/restaurants-consts';
import type { ConfigureState } from '../types/types';
import {
  getDeleteAction,
  getDuplicateAction,
  getRenameAction,
  getSetAsHomePageAction,
} from './pageActions';
import type { PagesBuilder, TFunction } from '@wix/yoshi-flow-editor';
import type { PageSettings } from '@wix/platform-editor-sdk';

export const configureStates: ConfigureState[] = [
  {
    stateName: RESERVATIONS_PAGES.reservations,
    actions: [getRenameAction, getSetAsHomePageAction, getDeleteAction],
    settingsTabs: ['pageInfo', 'permissions', 'layout', 'seo'],
  },
  {
    stateName: RESERVATIONS_PAGES.reservationDetails,
    settingsTabs: ['pageInfo', 'permissions', 'layout'],
  },
  {
    stateName: RESERVATIONS_PAGES.reservationConfirmation,
    settingsTabs: ['pageInfo', 'permissions', 'layout'],
  },
  {
    stateName: MENUS_PAGE_ID,
    actions: [
      getRenameAction,
      getSetAsHomePageAction,
      getDuplicateAction,
      getDeleteAction,
    ],
    settingsTabs: ['pageInfo', 'permissions', 'layout', 'seo'],
  },
  {
    stateName: ORDERS_PAGES.orderOnline,
    actions: [getRenameAction, getSetAsHomePageAction, getDeleteAction],
    settingsTabs: ['pageInfo', 'permissions', 'layout', 'seo'],
  },
];

export const getOloPageConfigureState = (
  oloPageId: string,
): ConfigureState => ({
  stateName: oloPageId,
  actions: [getRenameAction, getSetAsHomePageAction, getDeleteAction],
  settingsTabs: ['pageInfo', 'permissions', 'layout', 'seo'],
});

export const buildConfigureState = ({
  pagesBuilder,
  configureState,
  settingsTabs,
  t,
  isEditorX,
}: {
  pagesBuilder: PagesBuilder;
  configureState: ConfigureState;
  settingsTabs: Record<string, PageSettings>;
  t: TFunction;
  isEditorX: boolean;
}) => {
  pagesBuilder.configureState(configureState.stateName, (stateBuilder) => {
    const stateBuilderAfterActions = (configureState.actions ?? []).reduce(
      (builder, action) => builder.addAction(action(t, isEditorX)),
      stateBuilder,
    );

    (configureState.settingsTabs ?? []).reduce(
      (builder, settingsTab) =>
        builder.addSettingsTab(settingsTabs[settingsTab]),
      stateBuilderAfterActions,
    );
  });
};
