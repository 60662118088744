import type { PageSettings } from '@wix/platform-editor-sdk';
import type { TFunction } from '@wix/yoshi-flow-editor';

export const getSettingsTabs = (t: TFunction): Record<string, PageSettings> => {
  const pageInfo = {
    title: t('editor-script.actions.page-info'),
    type: 'page_info' as const,
  };

  const layout = {
    title: t('editor-script.actions.layout'),
    type: 'layout' as const,
  };

  const seo = {
    title: t('editor-script.actions.seo'),
    type: 'seo' as const,
    url: 'https://editor.wix.com/_serverless/restaurants-seo-settings-tab-serverless/seoSettings',
  };

  const permissions = {
    title: t('editor-script.actions.permissions'),
    type: 'permissions' as const,
  };

  return {
    pageInfo,
    layout,
    seo,
    permissions,
  };
};
