import type { EditorSDK } from '@wix/platform-editor-sdk';

export const isAppInstalled = (
  editorSDK: EditorSDK,
  appDefinitionId: string,
) => {
  return editorSDK.application.isApplicationInstalled('', {
    appDefinitionId,
  });
};
